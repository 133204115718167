import logos from "../../assets/image/logo 2.svg";
import image from "../../assets/image/img.svg";
import image1 from "../../assets/image/img1.svg";
import star from "../../assets/image/star.svg";
import star1 from "../../assets/image/star1.svg";
import frame from "../../assets/image/Frame 3.svg";

const AppResource = {
  // images
  images: {
    banner: require("../../assets/image/background.png"),
    logo: require("../../assets/image/logo.png"),
    image,
    logos,
    image1,
    star,
    star1,
    frame,
  },

  // colors
  colors: {
    mainBGColor: "#F2F6FD",
    featureColor: "#E92E4E",
    remainingColor: "#2E62E9",
  },
};

export default AppResource;
