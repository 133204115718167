import React, { useEffect, useState } from "react";
import AppResource from "../../../../general/constants/AppResource";
import Filter from "../Dropdown";
import StyleButton from "../StyleButton";
import "./style.scss";

function ChangeList(props) {
  const {
    setDataPlay,
    dataPlay,
    listData,
    setFilter,
    setSearch,
    dataCheck,
    setDataCheck,
    dataPause,
    setDataM3u8,
    setDataPause,
    setDataLoad,
    dataLoad,
  } = props;
  const [id, setId] = useState();
  useEffect(() => {
    setId(listData?.indexOf(dataPlay) ?? 0);
    // setDataCheck(false);
    setDataCheck({ url: dataPlay?.url, check: false });
    setDataLoad(false);
  }, [dataPlay]);

  return (
    <div className="change-list">
      <div className="list position-absolute w-100 ">
        <div className="d-flex justify-content-center list-header">
          <img className="logo" src={AppResource.images.logos} alt="avatar" />
          <div className="text">
            <h2 className="mb-0 d-flex justify-content-start text-ipfm">
              IPFM
            </h2>
            <span className="mb-0 d-flex justify-content-start name-text">
              Truyền thanh kỹ thuật số
            </span>
          </div>
        </div>

        <div className="">
          <div className="search-change">
            <div
              className="d-flex search-changes"
              style={{ width: "80%", margin: "0 auto" }}
            >
              <input
                className="search"
                placeholder="Tìm kiếm kênh"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              ></input>
              <div className="filter">
                <Filter setFilter={setFilter} />
              </div>
              <div className="icon-search">
                <i className="fas fa-search d-flex align-items-center "></i>
                {/* <span>Tìm kiếm</span> */}
              </div>
            </div>
            <div
              className="group-list"
              style={{ width: "80%", marginBottom: "130px" }}
            >
              <div className="list-change">
                <div
                  className="w-100 body-change"
                  style={{ borderRadius: "12px" }}
                >
                  {listData?.length > 0 ? (
                    <>
                      {listData?.map((item, index) => (
                        <div
                          key={index}
                          className="d-flex list-items justify-content-between"
                          style={
                            id === index && item?.name === dataPlay?.name
                              ? { background: "#007BFF" }
                              : { background: "#ffffffe6" }
                          }
                          onClick={() => {
                            setDataPlay(item);
                            setDataM3u8("");
                            setDataPause(false);
                          }}
                        >
                          <div className="d-flex group">
                            <img
                              className="image-list"
                              src={
                                id === index && item?.name === dataPlay?.name
                                  ? AppResource.images.image1
                                  : AppResource.images.image
                              }
                              alt=""
                            />

                            <div
                              className="title-header position-relative"
                              style={
                                id === index && item?.name === dataPlay?.name
                                  ? { marginTop: "2px" }
                                  : { marginTop: "5px" }
                              }
                            >
                              <h2
                                className="name-header"
                                style={
                                  id === index && item?.name === dataPlay?.name
                                    ? {
                                        color: "#fff",
                                        width: "90%",
                                        fontSize: "24px",
                                        lineHeight: "30px",
                                        marginBottom: "0px",
                                      }
                                    : item?.available === 0
                                    ? { color: "red", marginBottom: "4px" }
                                    : { marginBottom: "4px" }
                                }
                              >
                                {item?.name}
                                {id === index &&
                                  item?.name === dataPlay?.name && (
                                    <>
                                      {!dataCheck.check === true ? (
                                        <>
                                          {!dataPause ? (
                                            <>
                                              {dataLoad ? (
                                                <div className="position-absolute now icon-play d-none">
                                                  <span className="bar n1">
                                                    A
                                                  </span>
                                                  <span className="bar n2">
                                                    B
                                                  </span>
                                                  <span className="bar n3">
                                                    c
                                                  </span>
                                                  <span className="bar n4">
                                                    c
                                                  </span>
                                                </div>
                                              ) : (
                                                <div className="position-absolute icon-load d-none">
                                                  <div class="spinner-4"></div>
                                                </div>
                                              )}
                                            </>
                                          ) : (
                                            <i
                                              className="fas fa-play-circle text-light me-2 position-absolute d-none icon-play"
                                              style={{
                                                fontWeight: "500",
                                                fontSize: "16px",
                                              }}
                                            ></i>
                                          )}
                                        </>
                                      ) : (
                                        <i
                                          className="fal fa-exclamation-triangle me-2 position-absolute d-none icon-play"
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "14px",
                                            color: "#ffeb00e0",
                                          }}
                                        ></i>
                                      )}
                                    </>
                                  )}
                              </h2>
                              <div
                                className="d-flex justify-content-start link-header"
                                style={
                                  id === index && item?.name === dataPlay?.name
                                    ? { color: "#fff", marginBottom: "8px" }
                                    : { marginBottom: "8px" }
                                }
                              >
                                <i className="fas fa-link me-2 d-flex align-items-center"></i>

                                <div className="text-link">
                                  {item?.url === null ? (
                                    "Không có đường dẫn"
                                  ) : (
                                    <>{item?.url}</>
                                  )}
                                </div>
                              </div>
                              <div className="d-flex address">
                                <div
                                  className="d-flex justify-content-start pe-3 items"
                                  style={
                                    id === index &&
                                    item?.name === dataPlay?.name
                                      ? { borderRight: "2px solid #FFFFFF" }
                                      : { borderRight: "2px solid #C9CCD5" }
                                  }
                                >
                                  <i
                                    className="fas fa-map-marker-alt d-flex align-items-center me-2"
                                    style={
                                      id === index &&
                                      item?.name === dataPlay?.name
                                        ? { color: "#fff" }
                                        : {}
                                    }
                                  ></i>
                                  <span
                                    className="province me-2 d-flex align-items-center"
                                    style={
                                      id === index &&
                                      item?.name === dataPlay?.name
                                        ? {
                                            background: "#fff",
                                            color: "#007BFF",
                                          }
                                        : {}
                                    }
                                  >
                                    {item?.area}
                                  </span>
                                </div>
                                <div className="d-flex justify-content-start items1">
                                  <i
                                    className="far fa-broadcast-tower d-flex align-items-center me-2"
                                    style={
                                      id === index &&
                                      item?.name === dataPlay?.name
                                        ? { color: "#fff" }
                                        : {}
                                    }
                                  ></i>
                                  <div className="items-province">
                                    <StyleButton
                                      type={item?.type}
                                      id={id}
                                      index={index}
                                      dataPlay={dataPlay}
                                      item={item}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {id === index && item?.name === dataPlay?.name && (
                            <>
                              {dataCheck.check === true &&
                              dataCheck?.url === item.url ? (
                                <div className="icon-list me-3">
                                  <i
                                    className="fal fa-exclamation-triangle me-2 font-weight-bold"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "14px",
                                      color: "#ffeb00e0",
                                    }}
                                  ></i>
                                  <span
                                    className=""
                                    style={{
                                      minWidth: "max-content",
                                      fontWeight: "500",
                                      color: "#ffeb00e0",
                                    }}
                                  >
                                    Lỗi
                                  </span>
                                </div>
                              ) : (
                                <div className="icon-list me-3">
                                  {!dataPause ? (
                                    <>
                                      {dataLoad ? (
                                        <>
                                          <div className="now icon-play d-flex">
                                            <span className="bar n1">A</span>
                                            <span className="bar n2">B</span>
                                            <span className="bar n3">c</span>
                                            <span className="bar n4">c</span>
                                          </div>
                                          <span
                                            className="text-light"
                                            style={{
                                              minWidth: "max-content",
                                            }}
                                          >
                                            Đang phát
                                          </span>
                                        </>
                                      ) : (
                                        <div class="lds-spinner">
                                          <div></div>
                                          <div></div>
                                          <div></div>
                                          <div></div>
                                          <div></div>
                                          <div></div>
                                          <div></div>
                                          <div></div>
                                          <div></div>
                                          <div></div>
                                          <div></div>
                                          <div></div>
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <>
                                        <i className="fas fa-play text-light me-2"></i>
                                        <span
                                          className="text-light"
                                          style={{
                                            minWidth: "max-content",
                                          }}
                                        >
                                          Tạm dừng
                                        </span>
                                      </>
                                    </>
                                  )}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="bg-back" style={{ height: "50vh" }}>
                      <span className="d-flex h-100 align-items-center justify-content-center">
                        Không tìm thấy kết quả
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangeList;
