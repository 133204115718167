// api/axiosClient.js
import axios from "axios";

import queryString from "query-string";

const sTag = "[AxiosClient]";

// Cai dat config mac dinh cho http request
// Tham khao: `https://github.com/axios/axios#request-config`
// de xem chi tiet
/**
 * @type {import('general/types').AxiosInstance}
 */
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // headers: {
  //   "content-type": "application/json",
  // },
  // paramsSerializer: (params) => queryString.stringify(params),
});

// axiosClient.interceptors.request.use(async (config) => {
//   return config;
// });

axiosClient.interceptors.response.use(
  (response) => {
    if (response.headers["session-token"])
      axiosClient.defaults.headers.common["session-token"] =
        response.headers["session-token"];

    if (response && response.data) {
      if (response.status == 201) {
        return { status: 201, ...response.data };
      }
      return response.data;
    }

    return response;
  },
  (error) => {
    console.log(`${sTag} - ${error}`);
    let errorMessage = null;
    const response = error.response;
    console.log({ response });
    // if (response && response.status === 404) {
    //   UserHelper.signOut();
    // }
    // if (response && response.status === 401 || response.status === 403) {
    //   UserHelper.signOut();
    //   window.location.href = "/sign-in";
    //   return;
    // }
    // if (response && response.data) {
    //   const data = response.data;
    //   const { result, reason, detail } = data;
    //   if (result === "failed") {
    //     if (reason) {
    //       errorMessage = reason;
    //     } else if (detail) {
    //       errorMessage = detail;
    //     }
    //   }
    // }
    // if (errorMessage) {
    //   ToastHelper.showError(errorMessage);
    // }
    throw error;
  }
);

export default axiosClient;
