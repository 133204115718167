import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import "./style.scss";

function Filter(props) {
  const { setFilter } = props;
  const [dataActive, setDataActive] = useState("Tất cả");
  useEffect(() => {
    if (dataActive === "Tất cả") {
      setFilter("");
    }
    if (dataActive === "Digital FM (DFM)") {
      setFilter("DFM");
    }
    if (dataActive === "File MP3 (MP3)") {
      setFilter("MP3");
    }
    if (dataActive === "Microphone (Mic)") {
      setFilter("MIC");
    }
    if (dataActive === "Audio (Aux)") {
      setFilter("AUX");
    }
  }, [dataActive]);
  return (
    <DropdownButton
      id="dropdown-basic-button"
      title={
        <>
          <span
            className="me-1 title-dropdown"
            style={{ fontWeight: "600", color: "#6B7390", fontSize: "16px" }}
          >
            <div
              className="text-source"
              style={{
                minWidth: "max-content",
                fontSize: "16px",
                fontWeight: "600",
                color: "#1D2831",
              }}
            >
              Lựa chọn nguồn phát
            </div>
            {dataActive}
          </span>
          <i className="fas fa-filter icons-bar d-none text-light"></i>
        </>
      }
    >
      <Dropdown.Item
        style={{
          padding: "12px 16px",
          borderBottom: "1px solid rgba(74, 74, 74, 0.1)",
        }}
        href="#/action-1"
        onClick={() => {
          setDataActive("Tất cả");
        }}
      >
        <div className="d-flex justify-content-between">
          <span> Tất cả </span>
          {dataActive === "Tất cả" && (
            <i className="far fa-check ms-4 text-success"></i>
          )}
        </div>
      </Dropdown.Item>
      <Dropdown.Item
        style={{
          padding: "12px 16px",
          borderBottom: "1px solid rgba(74, 74, 74, 0.1)",
        }}
        href="#/action-2"
        onClick={() => {
          setDataActive("Digital FM (DFM)");
        }}
      >
        <div className="d-flex justify-content-between">
          <span> Digital FM (DFM) </span>
          {dataActive === "Digital FM (DFM)" && (
            <i className="far fa-check ms-4 text-success"></i>
          )}
        </div>
      </Dropdown.Item>
      <Dropdown.Item
        style={{
          padding: "12px 16px",
          borderBottom: "1px solid rgba(74, 74, 74, 0.1)",
        }}
        href="#/action-3"
        onClick={() => {
          setDataActive("File MP3 (MP3)");
        }}
      >
        <div className="d-flex justify-content-between">
          <span>File MP3 (MP3) </span>
          {dataActive === "File MP3 (MP3)" && (
            <i className="far fa-check ms-4 text-success"></i>
          )}
        </div>
      </Dropdown.Item>
      <Dropdown.Item
        style={{
          padding: " 12px 16px",
          borderBottom: "1px solid rgba(74, 74, 74, 0.1)",
        }}
        href="#/action-4"
        onClick={() => {
          setDataActive("Microphone (Mic)");
        }}
      >
        <div className="d-flex justify-content-between">
          <span> Microphone (Mic) </span>
          {dataActive === "Microphone (Mic)" && (
            <i className="far fa-check ms-4 text-success"></i>
          )}
        </div>
      </Dropdown.Item>
      <Dropdown.Item
        style={{ padding: " 12px 16px" }}
        href="#/action-4"
        onClick={() => {
          setDataActive("Audio (Aux)");
        }}
      >
        <div className="d-flex justify-content-between">
          <span> Audio (Aux) </span>
          {dataActive === "Audio (Aux)" && (
            <i className="far fa-check ms-4 text-success"></i>
          )}
        </div>
      </Dropdown.Item>
    </DropdownButton>
  );
}

export default Filter;
