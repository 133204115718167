import React from "react";
import MainHomeScreen from "./screens/Main";

Home.propTypes = {};

function Home(props) {
  return (
    <div>
      <MainHomeScreen />
    </div>
  );
}

export default Home;
