import Hls from "hls.js";
import Plyr from "plyr";
import React, { useEffect, useMemo, useRef, useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import AppResource from "../../../../general/constants/AppResource";
import "./style.scss";
function Player(props) {
  const {
    dataPlay,
    listData,
    setDataPlay,
    setDataCheck,
    setDataPause,
    dataPause,
    setDataM3u8,
    dataM3u8,
    setDataLoad,
  } = props;

  const [currentTrack, setTrackIndex] = useState(0);
  const [volume, setVolume] = useState(1);
  const [muted, setMuted] = useState(false);
  useEffect(() => {
    if (listData) {
      setDataPlay(listData[currentTrack]);
    }
  }, [currentTrack]);
  const handleClickNext = () => {
    setDataM3u8("");
    setDataCheck({ url: dataPlay?.url, check: true });
    setTrackIndex((currentTrack) =>
      currentTrack < listData?.length - 1 ? currentTrack + 1 : 0
    );
  };
  const handleClickPrevious = () => {
    setDataM3u8("");
    setDataCheck({ url: dataPlay?.url, check: true });
    setTrackIndex((currentTrack) =>
      currentTrack === 0 ? listData?.length - 1 : currentTrack - 1
    );
  };
  let dataIndex = listData?.indexOf(dataPlay);
  useEffect(() => {
    if (dataIndex !== -1) {
      setTrackIndex(dataIndex ?? 0);
    }

    if (dataPlay?.url === null) {
      setDataCheck({ url: dataPlay?.url, check: true });
    }
  }, [dataPlay]);

  const audioM3u8 =
    dataPlay?.url && dataPlay?.url !== null ? dataPlay?.url.slice(-5) : "";

  useEffect(() => {
    if (audioM3u8?.includes("3u8")) {
      setDataM3u8(dataPlay?.url);
    }
  }, [audioM3u8, dataPlay?.url]);
  useEffect(() => {
    setDataPause(false);
    setDataLoad(false);
  }, [dataPlay]);
  /// m3u8
  const refPlayer = useRef(null);
  var audio = document.getElementById("player");
  if (audio) {
    audio.onloadeddata = function () {
      setDataLoad(true);
    };
    audio.onplay = () => {
      setDataPause(false);
    };
    audio.onpause = () => {
      setDataPause(true);
    };
    audio.onerror = () => {
      setDataCheck({ url: dataPlay?.url, check: true });
    };
  }

  var hls = new Hls();
  useEffect(() => {
    if (Hls.isSupported() && dataM3u8) {
      hls.loadSource(dataM3u8);
      hls.attachMedia(audio);
    }
    hls.on(Hls.Events.ERROR, function (event, data) {
      if (data?.url === dataPlay?.url) {
        if (data?.url === dataM3u8) {
          setDataCheck({ url: dataPlay?.url, check: true });
        } else {
          setDataCheck({ url: dataPlay?.url, check: false });
        }
      }
    });
  }, [dataM3u8, dataPlay]);

  Plyr.setup(audio);

  var vid = document.getElementById("players");
  if (vid) {
    vid.onloadeddata = function () {
      setDataLoad(true);
    };
    vid.onerror = function () {
      setDataCheck({ url: dataPlay?.url, check: true });
    };
    vid.onpause = function () {
      setDataPause(true);
    };
    vid.onplay = function () {
      setDataPause(false);
    };
  }
  var result = navigator.userAgent.toLowerCase();
  var isPhone = result.indexOf("phone") > -1;
  var isPad = result.indexOf("ipad") > -1;
  var macOs = result.indexOf("macOS") > -1;
  var safari =
    navigator.userAgent.search("Safari") >= 0 &&
    navigator.userAgent.search("Chrome") < 0;

  const sourceEl = useMemo(() => {
    return (
      <div className="iphone-audio">
        <audio
          id="players"
          controls
          preload="auto"
          autoPlay
          style={{ width: "100%" }}
          src={dataPlay?.url}
        ></audio>
      </div>
    );
  }, [dataPlay]);
  const audioRef = useRef(null);
  const handlePause = () => {
    audioRef?.current?.audio?.current?.pause();
    setDataPause(true);
  };
  const handlePlay = () => {
    audioRef?.current?.audio?.current?.play();
    setDataPause(false);
  };
  const sourcePlay = useMemo(() => {
    return (
      <AudioPlayer
        autoPlay
        ref={audioRef}
        src={dataPlay?.url !== null ? dataPlay?.url : ""}
        showSkipControls={true}
        showJumpControls={false}
        showDownloadProgress={false}
        showFilledProgress={false}
        onClickNext={handleClickNext}
        onClickPrevious={handleClickPrevious}
        onError={() => setDataCheck({ url: dataPlay?.url, check: true })}
        onPause={() => setDataPause(true)}
        onPlay={() => setDataPause(false)}
        onLoadedData={() => setDataLoad(true)}
      />
    );
  }, [dataPlay]);
  if (dataPlay?.default === 1) {
    setDataPause(audioRef?.current?.audio?.current?.paused);
  }
  const sourceWin = useMemo(() => {
    return (
      <>
        <audio
          ref={refPlayer}
          className="win"
          autoPlay
          preload="auto"
          id="player"
          controls
          style={{ width: "100%" }}
        ></audio>
        {!safari && (
          <>
            <i
              className="fas fa-step-forward icon-next"
              onClick={() => handleClickNext()}
            ></i>
            <i
              className="fas fa-step-backward icon-prev"
              onClick={() => handleClickPrevious()}
            ></i>
          </>
        )}
      </>
    );
  }, [dataPlay]);
  useEffect(() => {
    if (volume > 0) {
      setMuted(false);
    }
  }, [volume]);
  return (
    <div className="position-fixed player-body">
      <div className="player">
        <div className="player-left">
          <div className="d-flex w-100">
            <img
              className="image-list"
              src={AppResource.images.frame}
              alt=""
              style={{ paddingLeft: "20px" }}
            />
            <div className="title-player">
              <h2
                className="d-flex justify-content-start name"
                style={{ marginBottom: "2px" }}
              >
                <marquee className="w-100">{dataPlay?.name}</marquee>
              </h2>
              <div className="d-flex justify-content-start texts">
                <span
                  className="Path"
                  style={{ minWidth: "max-content", color: "#444D6F" }}
                >
                  Đường dẫn:
                </span>{" "}
                {/* <i className="fas fa-link me-2 d-flex align-items-center d-none path-link"></i> */}
                <span className="text-primary">
                  {dataPlay?.url ? dataPlay?.url : "Không có đường dẫn"}
                </span>
              </div>
              <div className="d-flex mt-1 text-player">
                <div className="d-flex justify-content-start text ">
                  <span
                    className="addresss"
                    style={{ minWidth: "max-content", color: "#444D6F" }}
                  >
                    Khu vực phát:
                  </span>
                  {/* <i className="fas fa-map-marker-alt d-flex align-items-center d-none marker-address"></i> */}
                  {/* <span className="province ms-2">{dataPlay?.area}</span> */}
                  <span
                    className="ms-2 text-primary"
                    style={{ minWidth: "max-content" }}
                  >
                    {dataPlay?.area}
                  </span>
                </div>
                <div className="d-flex justify-content-start text Signal ms-4">
                  <span
                    className="me-2"
                    style={{ minWidth: "max-content", color: "#444D6F" }}
                  >
                    Nguồn tín hiệu:
                  </span>
                  <span
                    className="text-primary"
                    style={{ minWidth: "max-content" }}
                  >
                    {dataPlay?.type}
                  </span>
                  {/* <StyleButton
                    type={dataPlay?.type}
                    className="bg-primary text-light"
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="audio-player">
          <div className="d-flex justify-content-between align-items-center h-100">
            <div className="me-10 d-flex align-items-center">
              <i
                className="fas fa-step-backward icon-prevs"
                onClick={() => handleClickPrevious()}
              ></i>

              {dataPause ? (
                <i
                  className="fas fa-play icon-general-play"
                  onClick={() => {
                    if (audioM3u8 && audioM3u8?.includes("3u8")) {
                      if (!isPhone && !isPad && !macOs) {
                        audio.play();
                      } else {
                        vid.play();
                      }
                    } else {
                      handlePlay();
                    }
                  }}
                ></i>
              ) : (
                <i
                  className="fas fa-pause icon-general-play"
                  onClick={() => {
                    if (audioM3u8 && audioM3u8?.includes("3u8")) {
                      if (!isPhone && !isPad && !macOs) {
                        audio.pause();
                      } else {
                        vid.pause();
                      }
                    } else {
                      handlePause();
                    }
                  }}
                ></i>
              )}
              <i
                className="fas fa-step-forward icon-nexts "
                onClick={() => handleClickNext()}
              ></i>
            </div>
            <section style={{ marginRight: "40px" }} className="section-volume">
              {muted ? (
                <i
                  className="fas fa-volume-slash cursor-pointer me-4"
                  style={{ color: "#8993B0" }}
                ></i>
              ) : (
                <i
                  className="fas fa-volume-up cursor-pointer me-4"
                  style={{ color: "#8993B0" }}
                ></i>
              )}
              <input
                type="range"
                style={{ cursor: "pointer" }}
                min={0}
                max={1}
                step={0.02}
                value={volume}
                onChange={(event) => {
                  setVolume(event.target.valueAsNumber);
                  if (audioRef.current?.audio?.current !== undefined) {
                    const audio = audioRef.current?.audio?.current;
                    audio.volume = event.target.valueAsNumber;
                  }
                  if (event.target.valueAsNumber === 0) {
                    setMuted(true);
                  }
                  if (refPlayer?.current !== null) {
                    const muterPlayer = refPlayer?.current;
                    muterPlayer.volume = event.target.valueAsNumber;
                  }
                }}
              />
            </section>
          </div>
          <div className="audio position-relative">
            {audioM3u8 && audioM3u8?.includes("3u8") ? (
              <>
                {!isPhone && !isPad && !macOs ? (
                  <>
                    {sourceWin}
                    <>
                      {dataPause ? (
                        <i
                          className="fas fa-play-circle position-absolute d-none player-win"
                          onClick={() => {
                            audio.play();
                          }}
                          style={{
                            fontSize: "35px",
                            color: "#22A0DB",
                            right: "30px",
                            cursor: "pointer",
                          }}
                        ></i>
                      ) : (
                        <i
                          className="fas fa-pause-circle position-absolute d-none pause-win"
                          onClick={() => audio.pause()}
                          style={{
                            fontSize: "35px",
                            color: "#22A0DB",
                            right: "30px",
                            cursor: "pointer",
                          }}
                        ></i>
                      )}
                    </>
                  </>
                ) : (
                  <>
                    {sourceEl}
                    <>
                      {dataPause ? (
                        <i
                          className="fas fa-play-circle position-absolute"
                          onClick={() => {
                            vid.play();
                          }}
                          style={{
                            fontSize: "35px",
                            color: "#22A0DB",
                            right: "30px",
                            cursor: "pointer",
                          }}
                        ></i>
                      ) : (
                        <i
                          className="fas fa-pause-circle position-absolute"
                          onClick={() => vid.pause()}
                          style={{
                            fontSize: "35px",
                            color: "#22A0DB",
                            right: "30px",
                            cursor: "pointer",
                          }}
                        ></i>
                      )}
                    </>
                  </>
                )}
              </>
            ) : (
              sourcePlay
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Player;
