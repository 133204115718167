import axiosClient from "./axiosClient";

/**
 * API lien quan danh ba
 */
const listPlayerApi = {
  getListPlayer: (params) => {
    const url = "/device/link";
    return axiosClient.get(url, params);
  },
};

export default listPlayerApi;
