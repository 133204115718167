import React, { useEffect, useState } from "react";
import ChangeList from "../../components/ChannelList";
import Player from "../../components/Player";

import listPlayerApi from "../../../../api/listPlayerApi";

function MainHomeScreen() {
  const [data, setData] = useState();
  const [dataPlay, setDataPlay] = useState();
  const [filter, setFilter] = useState("");
  const [search, setSearch] = useState("");
  const [dataCheck, setDataCheck] = useState(false);
  const [dataPause, setDataPause] = useState(false);
  const [dataM3u8, setDataM3u8] = useState();

  const [dataLoad, setDataLoad] = useState(false);

  useEffect(() => {
    const fetchApi = async () => {
      const res = await listPlayerApi.getListPlayer();
      setData(res?.Stations);
    };
    fetchApi();
  }, []);

  useEffect(() => {
    setDataPlay(data?.[0]);
  }, [data]);

  const newData = data?.filter((item) => {
    if (filter === "" && search === "") {
      return data;
    }
    if (filter === "" && search !== "") {
      return item?.name
        .toLocaleLowerCase()
        ?.includes(search.toLocaleLowerCase());
    }
    if (filter !== "" && search === "") {
      return item?.type === filter;
    } else {
      return (
        item?.type === filter &&
        item?.name.toLocaleLowerCase()?.includes(search.toLocaleLowerCase())
      );
    }
  });

  useEffect(() => {
    if (dataCheck?.check === true) {
      setDataLoad(true);
    }
  }, [dataCheck]);

  return (
    <div className="body-home">
      <ChangeList
        setDataPlay={setDataPlay}
        dataPlay={dataPlay}
        listData={newData}
        setFilter={setFilter}
        setSearch={setSearch}
        dataCheck={dataCheck}
        setDataCheck={setDataCheck}
        dataPause={dataPause}
        setDataM3u8={setDataM3u8}
        setDataPause={setDataPause}
        setDataLoad={setDataLoad}
        dataLoad={dataLoad}
      />
      <Player
        dataPlay={dataPlay}
        listData={newData}
        setDataPlay={setDataPlay}
        setDataCheck={setDataCheck}
        setDataPause={setDataPause}
        dataPause={dataPause}
        setDataM3u8={setDataM3u8}
        dataM3u8={dataM3u8}
        setDataLoad={setDataLoad}
      />
    </div>
  );
}

export default MainHomeScreen;
