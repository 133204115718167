import React from "react";
import "./style.scss";

function StyleButton(props) {
  const { type, className, id, index, dataPlay, item } = props;
  function renderType(type) {
    switch (type) {
      case "DFM":
        return (
          <div
            className={`d-flex dfm ${className} align-items-center`}
            style={
              id === index && item?.name === dataPlay?.name
                ? {
                    background: "#fff",
                    color: "#007BFF",
                  }
                : {}
            }
          >
            {/* <i className="fas fa-satellite-dish me-2"></i> */}
            <span className="d-flex align-items-center">DFM</span>
          </div>
        );
      case "AUX":
        return (
          <div
            className="d-flex mp3 align-items-center"
            style={
              id === index && item?.name === dataPlay?.name
                ? {
                    background: "#fff",
                    color: "#785bff",
                  }
                : {}
            }
          >
            {/* <i className="fas fa-music me-2"></i> */}
            <span>AUX</span>
          </div>
        );
      case "MP3":
        return (
          <div
            className="d-flex mp3 align-items-center"
            style={
              id === index && item?.name === dataPlay?.name
                ? {
                    background: "#fff",
                    color: "#785bff",
                  }
                : {}
            }
          >
            {/* <i className="fas fa-music me-2"></i> */}
            <span>MP3</span>
          </div>
        );
      case "MIC":
        return (
          <div
            className="d-flex mic align-items-center"
            style={
              id === index && item?.name === dataPlay?.name
                ? {
                    background: "#ff4ecd",
                    color: "#007BFF",
                  }
                : {}
            }
          >
            {/* <i className="fas fa-microphone me-2"></i> */}
            <span>MIC</span>
          </div>
        );

      default:
        return (
          <div className="d-flex offline">
            <i className="fas fa-microphone-slash me-2"></i>
            <span>Offline</span>
          </div>
        );
    }
  }
  return renderType(type);
}
export default StyleButton;
