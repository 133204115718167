import {
  BrowserRouter,
  Navigate,
  Route,
  Router,
  Routes,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./features/Home";

function App() {
  // MARK: --- Params ---

  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<Navigate to="/home" />} />;
        <Route path="/home" element={<Home />} />;
      </Routes>
    </BrowserRouter>
  );
}

export default App;
